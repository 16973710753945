import { useCallback, useRef } from 'react'

const useDebouncedCallback = <T extends unknown[]>(callback: (...args: T) => void, delay: number) => {
  const timeoutRef = useRef<number | undefined>(undefined)
  return useCallback(
    (...args: T) => {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = window.setTimeout(() => callback(...args), delay)
    },
    [callback, delay]
  )
}

export default useDebouncedCallback

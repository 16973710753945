import React from 'react'
import classNames from 'classnames'
import DOCUMENT_GROUP_ICON_BY_SLUG from 'images/document_groups_icon'
import { EmployeeDocumentGroupStatus } from 'employee/types/document_groups'
import Icon from './Icon'

const DocumentIcon = ({
  className,
  slug,
  label,
  status,
}: {
  slug: string
  label: string
  status?: EmployeeDocumentGroupStatus
  className?: string
}) => {
  const documentGroupIcon = DOCUMENT_GROUP_ICON_BY_SLUG[slug]

  return documentGroupIcon && status !== 'missing' ? (
    <img src={documentGroupIcon} className={classNames('w-10 h-10 shrink-0 object-contain', className)} alt={label} />
  ) : (
    <div
      className={classNames(
        'w-10 h-10 shrink-0 flex justify-center items-center bg-backgroundNeutralLight ',
        className
      )}
    >
      <Icon name="cloud_upload" className="w-6 h-6 shrink-0" />
    </div>
  )
}

export default DocumentIcon

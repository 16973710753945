import React from 'react'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import Text from 'components/Text'
import Pill from 'components/Pill'
import Divider from 'components/Divider'
import { useAgencies } from 'employee/queries/agencies'
import { TAgency } from 'employee/types/agencies'
import RowActive from './AgencyList/RowActive'
import RowPending from './AgencyList/RowPending'

const AgencyList = () => {
  const { data: agencies } = useAgencies()
  const navigate = useNavigate()

  const navigateToAgency = (agency: TAgency) => {
    navigate(`/agencies/${agency.id}`)
  }

  return (
    <div className="flex flex-col h-full px-6 pt-12 pb-2">
      <div>
        <Text type="title">{i18next.t('employee.agency_list.title')}</Text>
        {agencies.pending.length > 0 ? (
          <Text type="subtext" className="pt-1 pb-4">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: i18next.t('employee.agency_list.pending_invites_subtitle', {
                  count: agencies.pending.length,
                }),
              }}
            />
          </Text>
        ) : null}
      </div>
      {agencies.pending.length > 0 ? (
        <div id="pendingInvites" className="py-4 w-full">
          <div className="flex items-center gap-3 mb-4">
            <Text type="subtitle">{i18next.t('employee.agency_list.pending_invites')}</Text>
            <Pill count={agencies.pending.length} isActive={false} />
          </div>
          {agencies.pending.map((agency) => (
            <RowPending key={agency.id} agency={agency} onClick={navigateToAgency} />
          ))}
        </div>
      ) : null}
      {agencies.active.length > 0 ? (
        <div id="myAgencies" className="py-4  w-full">
          <div className="flex items-center gap-3">
            <Text type="subtitle">{i18next.t('employee.agency_list.accepted_invites')}</Text>
            <Pill count={agencies.active.length} isActive={false} />
          </div>
          <Divider className="my-2" />
          {agencies.active.map((agency) => (
            <RowActive key={agency.id} agency={agency} onClick={navigateToAgency} />
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default AgencyList

import React, { useRef, useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import { captureException } from '@sentry/react'

import { UPLOAD_FILE_SIZE_ERROR, UPLOAD_FILE_TYPE_ERROR, convertFileToPdfIfNeeded, prepareFiles } from 'utils/post-file'
import { trackEvent } from 'utils/amplitude'
import {
  fetchDocumentGroup,
  useDocumentGroup,
  useDocumentUpload,
  useInvalidateDocumentGroupsQuery,
} from 'employee/queries/document_groups'
import { captureForDocumentGroupSlug } from 'employee/utils/document_groups'
import FirstFileSelection, { UploadMethod } from './DocumentUploadFlow/FirstFileSelection'
import BackImageSelection from './DocumentUploadFlow/BackImageSelection'
import ConfirmationStep from './DocumentUploadFlow/ConfirmationStep'
import DoubleSidedConfirmationDrawer from './DocumentUploadFlow/DoubleSidedConfirmationDrawer'
import { MultipleUploadInput, UploadInput } from './UploadInputs'

type DocumentUploadFlowProps = {
  slug: string
  documentGroupId: string
  onClose: () => void
  onFinish?: () => void
}

const DocumentUploadFlow = ({
  slug,
  documentGroupId,
  onClose,
  onFinish: optionalOnFinish,
}: DocumentUploadFlowProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const backInputRef = useRef<HTMLInputElement>(null)
  const imageInputRef = useRef<HTMLInputElement>(null)
  const backImageInputRef = useRef<HTMLInputElement>(null)

  const [fileToUpload, setFileToUpload] = useState<File>()
  const [backFileToUpload, setBackFile] = useState<File>()
  const [preparingFile, setPreparingFile] = useState(false)
  const [uploadWhenReady, setUploadWhenReady] = useState(false)
  const [showBackImageDrawer, setShowBackImageDrawer] = useState(false)
  const [showDoubleSidedConfirmationDrawer, setShowDoubleSidedConfirmationDrawer] = useState(false)

  const { data: documentGroup } = useDocumentGroup(documentGroupId)
  const invalidateDocumentGroupQuery = useInvalidateDocumentGroupsQuery()

  const navigate = useNavigate()
  const onFinish = optionalOnFinish ?? (() => navigate('/document_groups'))
  const { mutate: uploadFiles } = useDocumentUpload({
    onSuccess: () => {
      trackEvent('document-uploaded', {
        documentGroupId,
        documentSlug: slug,
      })
      invalidateDocumentGroupQuery()
      setUploadWhenReady(false)
      onFinish()
    },
    onError: (error: Error) => {
      setUploadWhenReady(false)
      setFileToUpload(undefined)
      if (error.message === UPLOAD_FILE_SIZE_ERROR) {
        toast.error(i18next.t('common.document_upload.max_size_error'))
      } else if (error.message === UPLOAD_FILE_TYPE_ERROR) {
        toast.error(i18next.t('common.document_upload.invalid_type'))
      } else {
        captureException(error)
        toast.error(i18next.t('common.document_upload.upload_error'))
        onFinish()
      }
    },
  })

  useEffect(() => {
    if (!uploadWhenReady || preparingFile) return
    if (!fileToUpload) return

    convertFileToPdfIfNeeded(fileToUpload, documentGroup.label).then((file) =>
      uploadFiles({ slug, documentGroup, file })
    )
  }, [uploadWhenReady, preparingFile])

  const doubleSided = fetchDocumentGroup(documentGroupId, slug)?.doubleSided
  const onConfirm = () => {
    trackEvent('document-upload-confirm', {
      documentGroupId,
      documentSlug: slug,
    })
    setUploadWhenReady(true)
  }

  const mergeFiles = async (files: File[]) => {
    setPreparingFile(true)

    setShowDoubleSidedConfirmationDrawer(false)
    const file = await prepareFiles(files, documentGroup.label)
    setFileToUpload(file)
    setBackFile(undefined)
    setPreparingFile(false)
  }

  const handleBackImage = async (backFile: File) => {
    if (!fileToUpload) return

    setBackFile(backFile)
    setShowBackImageDrawer(false)
    await mergeFiles([fileToUpload, backFile])
    setBackFile(undefined)
  }

  return (
    <>
      <form className="hidden">
        <UploadInput
          ref={imageInputRef}
          accept="image/*"
          capture={captureForDocumentGroupSlug(documentGroup.slug)}
          onChange={(file: File) => {
            setFileToUpload(file)
            if (doubleSided) {
              setShowBackImageDrawer(true)
            }
          }}
        />
        <UploadInput
          ref={backImageInputRef}
          accept="image/*"
          capture="environment"
          onChange={(file: File) => {
            handleBackImage(file)
          }}
        />
        <MultipleUploadInput
          ref={inputRef}
          numberOfFiles={doubleSided ? 2 : 1}
          onChange={(files: File[]) => {
            if (files.length > 1) {
              mergeFiles(files)
              setFileToUpload(files[0])
              setBackFile(files[1])
            } else if (files.length === 1) {
              setFileToUpload(files[0])
              if (doubleSided && files[0].type !== 'application/pdf') {
                setShowDoubleSidedConfirmationDrawer(true)
              }
            }
          }}
        />
        <UploadInput
          ref={backInputRef}
          onChange={(file: File) => {
            handleBackImage(file)
          }}
        />
      </form>
      {!fileToUpload ? (
        <FirstFileSelection
          slug={documentGroup.slug}
          onClose={onClose}
          onFileSelect={(uploadMethod: UploadMethod) => {
            if (uploadMethod === 'camera') {
              imageInputRef.current?.click()
            } else {
              inputRef.current?.click()
            }
          }}
        />
      ) : showBackImageDrawer ? (
        <BackImageSelection
          onFileSelect={() => {
            backImageInputRef.current?.click()
          }}
        />
      ) : (
        <ConfirmationStep
          files={backFileToUpload ? [fileToUpload, backFileToUpload] : [fileToUpload]}
          slug={slug}
          documentGroup={documentGroup}
          isPending={uploadWhenReady}
          onConfirm={onConfirm}
        />
      )}
      {showDoubleSidedConfirmationDrawer && (
        <DoubleSidedConfirmationDrawer
          onClose={() => setShowDoubleSidedConfirmationDrawer(false)}
          onBackImageSelect={() => {
            backInputRef.current?.click()
          }}
        />
      )}
    </>
  )
}

export default DocumentUploadFlow

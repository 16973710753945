import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import httpClient from 'utils/http-client'
import CommonResetPassword from 'components/ResetPassword'

const ResetPassword = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const token = new URLSearchParams(search).get('token')
  const expiredTokenUrl = '/employee/password/new?expired=true'

  if (!token) {
    navigate(expiredTokenUrl)
    return null
  }

  const resetPassword = (password: string) => {
    httpClient<void>('/employee/password', {
      method: 'put',
      body: { password, token },
    })
      .then(() => {
        navigate('/login')
      })
      .catch(() => navigate(expiredTokenUrl))
  }

  return <CommonResetPassword className="min-h-dvh px-6 py-6 bg-backgroundPrimary" resetPassword={resetPassword} />
}

export default ResetPassword

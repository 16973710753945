import Button from 'components/Button'
import Divider from 'components/Divider'
import Text from 'components/Text'
import i18next from 'i18next'
import React from 'react'
import ReactModal from 'react-modal'

type ConfirmModalProps = {
  onConfirmClick: () => void
  onCancelClick: () => void
  open: boolean
  title: string
}

const ConfirmModal = ({ open, title, onCancelClick, onConfirmClick }: ConfirmModalProps) => (
  <ReactModal
    isOpen={open}
    onRequestClose={onCancelClick}
    overlayClassName="fixed inset-0 bg-modalBackrop flex justify-center items-center"
    className="px-4 relative"
  >
    <div className="bg-white rounded-md p-4">
      <Text type="subtitle">{title}</Text>
      <Divider className="my-3" />
      <div className="flex flex-row gap-5 justify-end">
        <Button name="yes" type="button" uiStyle="delete" onClick={onConfirmClick}>
          {i18next.t('common.yes1')}
        </Button>
        <Button name="no" type="button" onClick={onCancelClick}>
          {i18next.t('common.no1')}
        </Button>
      </div>
    </div>
  </ReactModal>
)

export default ConfirmModal

import React from 'react'
import Icon from './Icon'

const Loader = () => (
  <svg className="animate-spin h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
    <Icon name="quarter_circle" className="opacity-75" uiStyle="accent" />
  </svg>
)

export default Loader

import React from 'react'
import Modal from 'react-modal'
import Text from 'components/Text'
import Button from 'components/Button'
import DocumentGroupPageContainer from 'employee/components/DocumentGroupPageContainer'

type AdviceModalProps = {
  onClose: () => void
  onButtonClick: () => void
  title: string
  info: string
  children: React.ReactNode
  buttonText: string
}

const AdviceModal = ({ onClose, onButtonClick, title, info, buttonText, children }: AdviceModalProps) => (
  <Modal
    isOpen
    onRequestClose={onClose}
    overlayClassName="bg-white fixed inset-0 flex justify-center items-end w-full"
    className="max-w-3xl min-h-dvh grow overflow-y-scroll"
  >
    <DocumentGroupPageContainer id="adviceModal" onBackPressed={onClose} iconName="filter_center_focus">
      <div className="text-center mb-3">
        <Text type="title">{title}</Text>
        <Text type="subtext">{info}</Text>
      </div>

      <div className="flex grow mb-4 items-center justify-center">{children}</div>

      <Button type="button" onClick={onButtonClick} name={buttonText}>
        {buttonText}
      </Button>
    </DocumentGroupPageContainer>
  </Modal>
)

export default AdviceModal

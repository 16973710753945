import React from 'react'
import { useDocumentGroup } from 'employee/queries/document_groups'
import UploadedDocumentList from 'employee/components/DocumentGroupFolder/UploadedDocumentList'
import DocumentGroupPageContainer from 'employee/components/DocumentGroupPageContainer'
import { useNavigate } from 'react-router-dom'

type DocumentGroupFolderProps = {
  documentGroupId: string
  slug: string
}

const DocumentGroupFolder = ({ documentGroupId, slug }: DocumentGroupFolderProps) => {
  const navigate = useNavigate()

  const { data: documentGroup } = useDocumentGroup(documentGroupId)

  const onBackPressed = () => {
    navigate(-1)
  }

  return (
    <DocumentGroupPageContainer id="documentGroupFolder" iconName="add" onBackPressed={onBackPressed}>
      <UploadedDocumentList documentGroup={documentGroup} slug={slug} />
    </DocumentGroupPageContainer>
  )
}

export default DocumentGroupFolder

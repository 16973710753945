import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'

import Button from 'components/Button'
import IconButton from 'components/IconButton'
import DocumentViewer from 'components/DocumentViewer'
import { TDocument } from 'types/documents'
import { TDocumentGroupWithExpiration } from 'employee/types/document_groups'
import DocumentHeader from './Document/DocumentHeader'
import DocumentContent from './Document/DocumentContent'

type DocumentProps = {
  slug: string
  documents: TDocument[]
  onClick: () => void
  buttonLabel: string
  documentGroup: TDocumentGroupWithExpiration
  buttonLoading?: boolean
  buttonVisible?: boolean
  onDeleteClick?: () => void
}

const Document = ({
  slug,
  documents,
  documentGroup,
  onClick,
  onDeleteClick,
  buttonLabel,
  buttonLoading,
  buttonVisible = true,
}: DocumentProps) => {
  const navigate = useNavigate()
  const [documentIndex, setDocumentIndex] = React.useState<number>(0)

  useEffect(() => {
    setDocumentIndex(0)
  }, [documents.length])

  return (
    <div className="flex flex-col h-dvh">
      {slug && <DocumentHeader onBackClick={() => navigate(-1)} onDeleteClick={onDeleteClick} slug={slug} />}
      <div className="h-96 p-6 bg-backgroundPrimary flex items-center justify-center relative">
        {documentIndex > 0 && (
          <IconButton
            className="absolute left-3"
            onClick={() => setDocumentIndex((documentIndex + 1) % documents.length)}
            name="chevron_left"
            size="medium"
            uiStyle="accent"
            label={i18next.t('common.previous')}
            rounded
          />
        )}
        {documents.length > 0 && documentIndex < documents.length && (
          <DocumentViewer document={documents[documentIndex]} className="max-w-[90%] max-h-[90%]" />
        )}
        {documentIndex < documents.length - 1 && (
          <IconButton
            className="absolute right-3"
            onClick={() => setDocumentIndex((documentIndex + 1) % documents.length)}
            name="chevron_right"
            size="medium"
            uiStyle="accent"
            label={i18next.t('common.next')}
            rounded
          />
        )}
      </div>
      <div className="flex p-6 flex-col grow justify-between">
        {documentGroup && documents.length > 0 && (
          <DocumentContent expirationDate={documents[0]?.expiration_date} requestedBy={documentGroup.requestedBy} />
        )}
      </div>
      {buttonVisible && (
        <div className="text-center pb-6 px-6">
          <Button loading={buttonLoading} type="submit" name={buttonLabel} className="w-full" onClick={onClick}>
            {buttonLabel}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Document

import React from 'react'

const sizeToScale = {
  medium: 1,
  large: 2.5,
}

type SkribeLogoProps = {
  className?: string
  iconFillColor?: string
  textFillColor?: string
  size?: keyof typeof sizeToScale
}

const SkribeLogo = ({
  className,
  iconFillColor = '#009076',
  textFillColor = '#000000',
  size = 'medium',
}: SkribeLogoProps) => (
  <svg
    width={105 * sizeToScale[size]}
    height={32 * sizeToScale[size]}
    viewBox="0 0 105 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M21.4614 14.6368C18.8102 16.5558 15.5334 17.6899 11.9867 17.6899H0C0 17.11 0.032048 16.5374 0.0945003 15.9738C0.965234 8.11583 7.74624 2 15.9823 2H27.969C27.969 2.57991 27.937 3.15248 27.8745 3.71608C27.4323 7.70682 25.4657 11.2482 22.5583 13.7674C22.4252 13.8828 22.29 13.996 22.153 14.1071C21.9276 14.2897 21.697 14.4664 21.4614 14.6368Z"
      fill={iconFillColor}
    />
    <path
      d="M3.68485 19.978C1.38374 22.6961 0 26.1894 0 30H11.9867C20.8135 30 27.969 22.9754 27.969 14.3101H25.3332C24.9411 14.7197 24.5298 15.111 24.1007 15.4829C23.9482 15.615 23.7935 15.7446 23.6365 15.8718C23.3784 16.0809 23.1143 16.2832 22.8445 16.4785C19.8063 18.6776 16.0478 19.978 11.9867 19.978H3.68485Z"
      fill={iconFillColor}
    />
    <path
      d="M44.9161 24.8135C43.4804 24.8135 42.2182 24.5845 41.1295 24.1265C40.0567 23.6684 39.2126 22.9971 38.5972 22.1126C37.9977 21.2123 37.6822 20.1067 37.6506 18.7957H40.7982C40.8455 20.0435 41.2479 20.928 42.0052 21.4492C42.7783 21.9547 43.7801 22.2074 45.0108 22.2074C46.0678 22.2074 46.9119 21.9942 47.543 21.5677C48.1741 21.1412 48.4897 20.541 48.4897 19.7671C48.4897 19.3248 48.3792 18.9615 48.1584 18.6772C47.9375 18.3929 47.5194 18.1323 46.9041 17.8954C46.2887 17.6585 45.3894 17.4215 44.2061 17.1846C42.7704 16.9319 41.6107 16.5923 40.7272 16.1658C39.8594 15.7394 39.2047 15.1866 38.7629 14.5074C38.3211 13.8124 38.1003 12.9595 38.1003 11.9486C38.1003 11.0167 38.3606 10.1954 38.8812 9.48461C39.4177 8.75805 40.1592 8.19733 41.1059 7.80246C42.0683 7.39179 43.1727 7.18646 44.4191 7.18646C46.6437 7.18646 48.3398 7.69979 49.5073 8.72646C50.6748 9.73733 51.2823 11.1273 51.3296 12.8963H48.253C48.1899 11.8538 47.8428 11.0799 47.2117 10.5745C46.5964 10.0532 45.6655 9.79261 44.4191 9.79261C43.504 9.79261 42.7625 9.98215 42.1945 10.3612C41.6423 10.7245 41.3662 11.2221 41.3662 11.8538C41.3662 12.3119 41.4687 12.691 41.6738 12.9911C41.8789 13.2754 42.2655 13.536 42.8335 13.7729C43.4015 14.0098 44.2456 14.2389 45.3658 14.46C47.0224 14.7443 48.3082 15.1234 49.2233 15.5972C50.1542 16.0553 50.809 16.6081 51.1876 17.2557C51.5663 17.9033 51.7556 18.693 51.7556 19.6249C51.7556 20.7148 51.4637 21.6467 50.88 22.4206C50.312 23.1946 49.5073 23.7869 48.466 24.1975C47.4405 24.6082 46.2572 24.8135 44.9161 24.8135Z"
      fill={textFillColor}
    />
    <path
      d="M53.0752 7.47077H56.0571V17.3505L61.0033 12.2092H64.5769L59.9147 16.924L64.9082 24.5292H61.4293L57.8557 19.0326L56.0571 20.8332V24.5292H53.0752V7.47077Z"
      fill={textFillColor}
    />
    <path
      d="M65.7339 12.2092H68.6212V14.3415C69.0156 13.536 69.5205 12.9595 70.1358 12.612C70.7669 12.2487 71.5005 12.0671 72.3367 12.0671H73.3071V14.8154H72.2184C71.4769 14.8154 70.8458 14.9338 70.3251 15.1708C69.8203 15.4077 69.4258 15.8026 69.1418 16.3554C68.8578 16.9082 68.7158 17.6427 68.7158 18.5588V24.5292H65.7339V12.2092Z"
      fill={textFillColor}
    />
    <path
      d="M74.625 12.2092H77.5832V24.5292H74.625V12.2092ZM74.625 7.47077H77.6069V10.2428H74.625V7.47077Z"
      fill={textFillColor}
    />
    <path
      d="M86.3567 24.8135C85.4889 24.8135 84.7316 24.6477 84.0847 24.316C83.4379 23.9685 82.8935 23.4789 82.4518 22.8471V24.5292H79.5882V7.47077H82.5701V13.7492C82.9803 13.1648 83.501 12.7147 84.132 12.3988C84.7789 12.0829 85.5205 11.9249 86.3567 11.9249C87.5242 11.9249 88.5182 12.2013 89.3386 12.7542C90.1748 13.307 90.8059 14.073 91.2319 15.0523C91.6579 16.0158 91.8709 17.1214 91.8709 18.3692C91.8709 19.617 91.6579 20.7306 91.2319 21.7098C90.8059 22.6733 90.1748 23.4315 89.3386 23.9843C88.5182 24.5371 87.5242 24.8135 86.3567 24.8135ZM85.6703 22.3258C86.3803 22.3258 86.9641 22.1679 87.4216 21.852C87.895 21.5203 88.25 21.0623 88.4866 20.4778C88.7233 19.8776 88.8416 19.1748 88.8416 18.3692C88.8416 17.5637 88.7233 16.8687 88.4866 16.2843C88.25 15.6841 87.895 15.2261 87.4216 14.9102C86.9641 14.5785 86.3803 14.4126 85.6703 14.4126C84.9604 14.4126 84.3687 14.5785 83.8954 14.9102C83.4378 15.2261 83.0907 15.6841 82.8541 16.2843C82.6332 16.8687 82.5228 17.5637 82.5228 18.3692C82.5228 19.1748 82.6332 19.8776 82.8541 20.4778C83.0907 21.0623 83.4378 21.5203 83.8954 21.852C84.3687 22.1679 84.9604 22.3258 85.6703 22.3258Z"
      fill={textFillColor}
    />
    <path
      d="M98.9178 24.8135C97.6714 24.8135 96.5827 24.5371 95.6519 23.9843C94.7368 23.4315 94.0347 22.6654 93.5456 21.6862C93.0723 20.7069 92.8356 19.6012 92.8356 18.3692C92.8356 17.1372 93.0723 16.0316 93.5456 15.0523C94.0347 14.073 94.7368 13.307 95.6519 12.7542C96.5827 12.2013 97.6714 11.9249 98.9178 11.9249C100.164 11.9249 101.245 12.2013 102.16 12.7542C103.075 13.307 103.777 14.0888 104.266 15.0997C104.755 16.0948 105 17.2636 105 18.6062V19.3169H95.7939C95.8728 19.9645 96.0463 20.5252 96.3145 20.9991C96.5827 21.4571 96.9377 21.8204 97.3795 22.0889C97.8213 22.3416 98.334 22.468 98.9178 22.468C99.6278 22.468 100.227 22.318 100.716 22.0178C101.206 21.7177 101.584 21.2992 101.852 20.7622H104.763C104.353 22.0099 103.635 22.9971 102.61 23.7237C101.6 24.4503 100.369 24.8135 98.9178 24.8135ZM101.994 17.1846C101.837 16.2843 101.49 15.5735 100.953 15.0523C100.432 14.5311 99.754 14.2705 98.9178 14.2705C98.0658 14.2705 97.3795 14.5311 96.8588 15.0523C96.3382 15.5735 95.999 16.2843 95.8412 17.1846H101.994Z"
      fill={textFillColor}
    />
  </svg>
)

export default SkribeLogo

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { TAgency, TAgencyList } from 'employee/types/agencies'
import httpClient from 'utils/http-client'
import { useInvalidateDocumentGroupsQuery } from './document_groups'

const AGENCIES_KEY = ['agencies']

export const useAgencies = () =>
  useQuery<TAgencyList>({
    queryKey: AGENCIES_KEY,
    queryFn: () => httpClient<TAgencyList>('/agencies'),
    initialData: {
      pending: [],
      active: [],
    },
  })

export type AgencyWithStatus = TAgency & { status: 'active' | 'pending' }

export const fetchAgency = (agencyId: string): AgencyWithStatus | undefined => {
  const {
    data: { active, pending },
  } = useAgencies()

  const activeAgency = active.find(({ id }) => id === agencyId)
  if (activeAgency) {
    return { ...activeAgency, status: 'active' }
  }
  const pendingAgency = pending.find(({ id }) => id === agencyId)
  if (pendingAgency) {
    return { ...pendingAgency, status: 'pending' }
  }
  return undefined
}

export const useAcceptInviteAgencies = (
  agencyId: string,
  { onSuccess, onError }: { onSuccess?: () => void; onError?: () => void } = {}
) => {
  const queryClient = useQueryClient()
  const invalidateDocumentGroupsQuery = useInvalidateDocumentGroupsQuery()

  return useMutation({
    mutationFn: () => httpClient<TAgency>(`/agencies/${agencyId}`, { method: 'put', body: { status: 'active' } }),
    onError,
    onSuccess: (agency: TAgency) => {
      queryClient.setQueryData(
        AGENCIES_KEY,
        (previousAgencyList: TAgencyList): TAgencyList => ({
          pending: previousAgencyList.pending.filter((pendingAgency) => agency.id !== pendingAgency.id),
          active: [...previousAgencyList.active, agency],
        })
      )
      invalidateDocumentGroupsQuery()
      if (onSuccess) {
        onSuccess()
      }
    },
  })
}

import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import { TDocument } from 'types/documents'
import usePdfViewer from 'hooks/usePdfViewer'
import IconButton from 'components/IconButton'
import FullScreenPdfModal from 'components/FullScreenPdfModal'

type MobilePdfViewerProps = {
  document: TDocument
}

export const MobilePdfViewer = ({ document }: MobilePdfViewerProps) => {
  const [displayFullScreen, setDisplayFullScreen] = useState(false)
  const { openPdf, nextPage, previousPage, numPages, currentPage } = usePdfViewer(document.url, 'pdf_canvas')

  useEffect(() => {
    openPdf()
  }, [])

  return (
    <>
      {currentPage > 1 && (
        <IconButton
          name="chevron_left"
          className="absolute left-3"
          size="medium"
          uiStyle="accent"
          rounded
          onClick={previousPage}
          label={i18next.t('common.previous')}
        />
      )}
      <canvas id="pdf_canvas" className="h-full" />
      {currentPage < numPages && (
        <IconButton
          className="absolute right-3"
          name="chevron_right"
          size="medium"
          uiStyle="accent"
          rounded
          onClick={nextPage}
          label={i18next.t('common.next')}
        />
      )}

      <IconButton
        className="absolute right-3 bottom-3 p-1"
        name="filter_center_focus"
        size="medium"
        uiStyle="accent"
        rounded
        onClick={() => setDisplayFullScreen(true)}
        label={i18next.t('common.next')}
      />
      {displayFullScreen && <FullScreenPdfModal document={document} closeModal={() => setDisplayFullScreen(false)} />}
    </>
  )
}

import React, { ChangeEvent } from 'react'
import classNames from 'classnames'

import Text from './Text'

type InputProps = {
  disabled?: boolean
  type: string
  placeholder?: string
  required?: boolean
  name: string
  label?: string
  value?: string
  maxLength?: number
  className?: string
  error?: string
  onChange?: (value: string) => void
  rightIcon?: React.ReactNode
}

const Input = ({
  type = 'text',
  placeholder = '',
  required = false,
  name,
  value,
  label,
  maxLength,
  className = '',
  error = '',
  disabled = false,
  rightIcon,
  onChange,
}: InputProps) => (
  <div className={classNames('flex flex-col items-start gap-1.5', className)}>
    {label && (
      <label htmlFor={name}>
        <Text type="text" uiStyle="bold">
          {`${label}${required ? '*' : ''}`}
        </Text>
      </label>
    )}
    <div className="relative w-full">
      <input
        className={classNames(
          'border rounded-lg py-2 px-3 focus:outline-2 hover:outline hover:outline-1 w-full data-[placeholder]:text-textPlaceholder',
          {
            'border-error hover:outline-error focus:outline-error': error,
            'hover:outline-primary focus:outline-primary': !error,
          }
        )}
        id={name}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        required={required}
        disabled={disabled}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange && onChange(event.target.value)}
        minLength={type === 'password' ? window.minimum_password_length : undefined}
        maxLength={maxLength}
      />
      {rightIcon && <div className="inline-flex absolute right-3 top-1/2 transform -translate-y-1/2">{rightIcon}</div>}
    </div>
    {error ? (
      <Text type="text" className="text-error">
        {error}
      </Text>
    ) : null}
  </div>
)

export default Input

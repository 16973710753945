export const compressImageSize = async (file: File): Promise<File> => {
  const blobURL = window.URL.createObjectURL(file)
  const img = new Image()
  img.src = blobURL

  if (!img) {
    throw new Error('Impossible to retrieve img context')
  }

  await new Promise((resolve) => {
    img.onload = resolve
  })

  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height

  const canvasContext = canvas.getContext('2d')

  if (!canvasContext) {
    throw new Error('Impossible to retrieve canvas context')
  }

  canvasContext.drawImage(img, 0, 0, img.width, img.height)

  // TODO Improve this quality decision
  // Currently this means that a 6MB goes to a 800KB file
  const quality = 0.5
  const blob = await new Promise((resolve) => {
    canvas.toBlob(resolve, 'image/jpeg', quality)
  })

  return new File([blob as Blob], file.name)
}

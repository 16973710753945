import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Employee, EmployeeFromEndpoint } from 'employee/types/employees'
import httpClient from 'utils/http-client'

const EMPLOYEE_KEY = ['employee']

export const useEmployee = () =>
  useQuery<Employee>({
    queryKey: EMPLOYEE_KEY,
    queryFn: () =>
      httpClient<EmployeeFromEndpoint>('/employee').then((data) => ({
        id: data.id,
        email: data.email,
        dataUsageConsentedAt: data.data_usage_consented_at,
        firstName: data.first_name,
        lastName: data.last_name,
      })),
    initialData: {
      id: '',
      email: '',
      lastName: '',
      firstName: '',
      dataUsageConsentedAt: null,
    },
  })

type SettingsParams = {
  dataUsageConsent: boolean
}

type SettingsParamsResult = {
  data_usage_consented_at: string | null
}

export const useUpdateSettings = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: SettingsParams) =>
      httpClient<SettingsParamsResult>('/employee/settings', {
        method: 'put',
        body: {
          data_usage_consent: data.dataUsageConsent,
        },
      }),
    onSuccess: ({ data_usage_consented_at }: { data_usage_consented_at: string | null }) => {
      queryClient.setQueryData(EMPLOYEE_KEY, (oldData: Employee) => ({
        ...oldData,
        dataUsageConsentedAt: data_usage_consented_at,
      }))
      if (onSuccess) {
        onSuccess()
      }
    },
  })
}

import React from 'react'
import classNames from 'classnames'

type DividerProps = {
  className?: string
}

const Divider = ({ className }: DividerProps) => (
  <div className={classNames('border-b border-borderMedium', className)} />
)

export default Divider

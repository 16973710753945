import React from 'react'
import i18next from 'i18next'

import httpClient from 'utils/http-client'
import CommonForgotPassword from 'components/ForgotPassword'
import Text from 'components/Text'

const DescriptionStep = ({ text }: { text: string }) => (
  <li className="mt-2">
    <Text className="inline">{text}</Text>
  </li>
)

const Description = () => (
  <>
    <Text type="text">{i18next.t('employee.forgot_password.description')}</Text>
    <ol className="list-decimal list-inside mt-2">
      <DescriptionStep text={i18next.t('employee.forgot_password.step_1')} />
      <DescriptionStep text={i18next.t('employee.forgot_password.step_2')} />
      <DescriptionStep text={i18next.t('employee.forgot_password.step_3')} />
    </ol>
  </>
)

const ForgotPassword = () => {
  const resetPassword = (email: string) =>
    httpClient<void>('/employee/password', {
      method: 'post',
      body: { email },
    })
  const title = i18next.t('employee.forgot_password.title')
  return (
    <CommonForgotPassword
      className="min-h-dvh px-6 py-6 bg-backgroundPrimary"
      title={title}
      description={<Description />}
      resetPassword={resetPassword}
    />
  )
}

export default ForgotPassword

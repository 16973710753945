/* eslint-disable react/no-danger */
import React, { KeyboardEvent, MouseEvent } from 'react'

type ClickableDivProps = {
  id?: string
  onClick: () => void
  label: string
  className?: string
  dangerouslySetInnerHTML?: { __html: string }
}

const ClickableDiv = ({
  id,
  children,
  label,
  onClick,
  className,
  dangerouslySetInnerHTML,
}: React.PropsWithChildren<ClickableDivProps>) => (
  <div
    id={id}
    role="button"
    className={className}
    tabIndex={0}
    aria-label={label}
    onClick={(event: MouseEvent<HTMLTableRowElement>) => {
      event.preventDefault()
      onClick()
    }}
    onKeyDown={(event: KeyboardEvent<HTMLTableRowElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        onClick()
      }
    }}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
  >
    {children}
  </div>
)

export default ClickableDiv

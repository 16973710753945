import React from 'react'
import i18next from 'i18next'

import Text from 'components/Text'
import Icon from 'components/Icon'
import StatusTag from 'employee/components/StatusTag'
import ClickableDiv from 'components/ClickableDiv'
import { EmployeeDocumentGroupStatus } from 'employee/types/document_groups'

type DocumentChoiceRowProps = {
  label: string
  displayStatusTag?: boolean
  displayIcon?: boolean
  status?: EmployeeDocumentGroupStatus
  onClick: () => void
}

const DocumentChoiceRow = ({
  label,
  status,
  onClick,
  displayStatusTag,
  displayIcon = true,
}: DocumentChoiceRowProps) => (
  <ClickableDiv
    onClick={onClick}
    label={i18next.t('employee.document_groups.add_document_aria_label', { label })}
    className="flex flex-row px-4 py-3 items-center justify-between cursor-pointer border rounded-lg border-borderStrong bg-white shadow-backgroundPrimary"
  >
    <div className="flex flex-row items-center gap-x-4 grow mr-2">
      {displayIcon && <Icon name="cloud_upload" className="w-8 h-8 shrink-0" />}

      <Text type="text">{label}</Text>
    </div>

    {displayStatusTag && status && <StatusTag status={status} />}
    <Icon name="chevron_right" className="ml-2 shrink-0" />
  </ClickableDiv>
)

export default DocumentChoiceRow

import React, { useState } from 'react'
import i18next from 'i18next'
import Icon, { IconName } from 'components/Icon'
import Button from 'components/Button'
import Text from 'components/Text'
import Chip from 'components/Chip'
import CheckBox from 'components/CheckBox'
import { trackEvent } from 'utils/amplitude'
import { OnChangeFormFunction } from 'employee/types/signup'
import Header from './Header'

type DisclaimerRowProps = {
  text: string
  icon: IconName
}

const DisclaimerRow = ({ text, icon }: DisclaimerRowProps) => (
  <div className="flex flex-row items-center mb-4 gap-3">
    <Chip
      size="medium"
      className="bg-white rounded-full flex items-center justify-center self-center shrink-0 grow-0 border-[1px] border-borderMedium"
    >
      <Icon name={icon} uiStyle="success" size="medium" />
    </Chip>
    <Text>{text}</Text>
  </div>
)

type DisclaimerProps = {
  onNext: () => void
  agencyName: string
  onChange: OnChangeFormFunction
  value: boolean
}

const Disclaimer = ({ onNext, agencyName, onChange, value }: DisclaimerProps) => {
  const [termsEnabled, setTermsEnabled] = useState(true)

  const handleOnNextClick = () => {
    trackEvent('signup-welcome-click')
    onNext()
  }

  return (
    <div className="grow flex flex-col">
      <div className="w-full flex flex-col grow pt-12">
        <Header
          icon="celebration"
          title={i18next.t(`employee.signup.disclaimer.title`)}
          subtitle={i18next.t('employee.signup.disclaimer.subtitle', { agency_name: agencyName })}
        />
        <div>
          <DisclaimerRow text={i18next.t('employee.signup.disclaimer.add_documents')} icon="assignement_add" />
          <DisclaimerRow text={i18next.t('employee.signup.disclaimer.pick_agencies')} icon="admin_panel_settings" />
          <DisclaimerRow text={i18next.t('employee.signup.disclaimer.update_documents')} icon="update" />
          <DisclaimerRow text={i18next.t('employee.signup.disclaimer.agencies_information')} icon="contact_support" />
        </div>
      </div>

      <CheckBox
        onChange={setTermsEnabled}
        name="skribe_terms"
        label={i18next.t('employee.signup.disclaimer.terms')}
        containerClassName="mb-3"
        checked={termsEnabled}
      />
      <CheckBox
        onChange={(checked: boolean) => onChange('data_usage_consent', checked)}
        name="skribe_document_terms"
        label={i18next.t('employee.signup.disclaimer.document_usage_terms')}
        checked={value}
      />
      <Button
        type="button"
        onClick={handleOnNextClick}
        name={i18next.t('common.continue')}
        className="my-6 w-full"
        disabled={!termsEnabled}
      >
        {i18next.t('common.continue')}
      </Button>
    </div>
  )
}

export default Disclaimer

import React from 'react'
import Icon, { IconProps } from 'components/Icon'
import classNames from 'classnames'

type IconButtonProps = IconProps & {
  onClick: () => void
  label: string
  iconClassName?: string
  rounded?: boolean
}

const IconButton = ({ name, className, iconClassName, size, onClick, label, rounded, uiStyle }: IconButtonProps) => (
  <button
    className={classNames('text-textSecondary', { 'rounded-full bg-white': rounded }, className)}
    type="button"
    aria-label={label}
    title={label}
    onClick={onClick}
  >
    <Icon name={name} className={iconClassName} size={size} uiStyle={uiStyle} />
  </button>
)

export default IconButton

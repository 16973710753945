import Chip from 'components/Chip'
import Icon, { IconName } from 'components/Icon'
import Text from 'components/Text'
import React from 'react'

type HeaderProps = {
  icon: IconName
  title: string
  subtitle: string
}

const Header = ({ icon, title, subtitle }: HeaderProps) => (
  <div className="z-0">
    <div className="relative flex justify-center mb-5">
      <Chip size="large" className="bg-white rounded-full flex items-center justify-center  self-center">
        <Icon name={icon} uiStyle="success" size="large" />
      </Chip>
      <svg
        className="z-[-1] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        width="600"
        height="200"
        viewBox="0 0 600 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="300" cy="100" r="175" stroke="rgba(184, 242, 232, 0.1)" strokeWidth="1" fill="none" />
        <circle cx="300" cy="100" r="145" stroke="rgba(184, 242, 232, 0.3)" strokeWidth="1" fill="none" />
        <circle cx="300" cy="100" r="115" stroke="rgba(184, 242, 232, 0.5)" strokeWidth="1" fill="none" />
        <circle cx="300" cy="100" r="85" stroke="rgba(184, 242, 232, 0.7)" strokeWidth="1" fill="none" />
        <circle cx="300" cy="100" r="55" stroke="rgba(184, 242, 232, 0.9)" strokeWidth="1" fill="none" />
        <circle cx="300" cy="100" r="28" stroke="rgba(184, 242, 232, 1)" strokeWidth="1" fill="none" />
      </svg>
    </div>
    <div className="text-center mb-10">
      <Text type="title">{title}</Text>
      <Text type="subtext">{subtitle}</Text>
    </div>
  </div>
)

export default Header

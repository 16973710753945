import React from 'react'
import i18next from 'i18next'
import Text from 'components/Text'
import AdviceModal from './components/AdviceModal'

type AddressProofAdviceModalProps = {
  onClose: () => void
  onContinueClick: () => void
}

const DOCUMENTS_LIST = [
  'employee.document_groups.upload_method_drawer.upload_advice.address_proof.phone',
  'employee.document_groups.upload_method_drawer.upload_advice.address_proof.taxes',
  'employee.document_groups.upload_method_drawer.upload_advice.address_proof.rent',
  'employee.document_groups.upload_method_drawer.upload_advice.address_proof.rent_contract',
  'employee.document_groups.upload_method_drawer.upload_advice.address_proof.rent_insirance',
]

const AddressProofAdviceModal = ({ onClose, onContinueClick }: AddressProofAdviceModalProps) => (
  <AdviceModal
    onClose={onClose}
    onButtonClick={onContinueClick}
    title={i18next.t('employee.document_groups.upload_method_drawer.upload_advice.title')}
    info={i18next.t('employee.document_groups.upload_method_drawer.upload_advice.address_proof.info')}
    buttonText={i18next.t('common.continue')}
  >
    <ul className="list-disc pl-3">
      {DOCUMENTS_LIST.map((document) => (
        <li key={document}>
          <Text className="mb-2">{i18next.t(document)}</Text>
        </li>
      ))}
    </ul>
  </AdviceModal>
)

export default AddressProofAdviceModal

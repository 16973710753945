import React from 'react'
import IconButton from 'components/IconButton'
import Text from 'components/Text'
import i18next from 'i18next'
import classNames from 'classnames'

const shouldOverrideTitleSize = (title: string) => title.length > 30

const DocumentHeader = ({
  slug,
  onDeleteClick,
  onBackClick,
}: {
  slug: string
  onBackClick: () => void
  onDeleteClick?: () => void
}) => {
  const title = i18next.t(`document_group_config.${slug}`)

  return (
    <div className="h-14 p-6 flex items-center justify-between">
      <IconButton name="arrow_back" label={i18next.t('common.go_back')} onClick={onBackClick} />
      <Text type="title" className={classNames('text-center', { 'text-base': shouldOverrideTitleSize(title) })}>
        {title}
      </Text>
      {onDeleteClick ? (
        <IconButton name="delete" label={i18next.t('common.delete')} onClick={onDeleteClick} className="text-white" />
      ) : (
        <div />
      )}
    </div>
  )
}

export default DocumentHeader

import { DocumentGroup } from 'employee/types/document_groups'
import { InputHTMLAttributes } from 'react'

export const displayStatusTagForDocumentGroup = (documentGroup?: DocumentGroup): boolean => {
  if (!documentGroup) return false
  return documentGroup?.subDocumentGroups.some((subDocumentGroup) => {
    if (subDocumentGroup.status !== 'missing') return true

    if (subDocumentGroup.kind === 'list') {
      return displayStatusTagForDocumentGroup(subDocumentGroup)
    }

    return false
  })
}

export const captureForDocumentGroupSlug = (slug: string): InputHTMLAttributes<HTMLInputElement>['capture'] =>
  slug === 'identity_photo' ? 'user' : 'environment'

import ConvertApi from 'convertapi-js'
import { isTestEnv } from './environment'
import httpClient from './http-client'
import constants from './constants'
import { trackEvent } from './amplitude'

const initializeConvertApi = async (): Promise<ConvertApi> => {
  const { token } = await httpClient<ConvertApiTokenResponse>('/convert_api_token')

  return ConvertApi.auth({ token, apiKey: constants.CONVERT_API_KEY }, 'eu-v2.convertapi.com')
}

const downloadFile = async (url: string, fileName: string): Promise<File> => {
  const result = await fetch(url)
  const resultBlob = await result.blob()
  return new File([resultBlob], fileName, { type: 'application/pdf' })
}

const imagesToPdf = async (files: File[], convertApi: ConvertApi): Promise<string> => {
  const params = convertApi.createParams()
  files.forEach((file, index) => params.add(`Files[${index}]`, file))
  const convertedFileResult = await convertApi.convert('images', 'pdf', params)
  return convertedFileResult.files[0].Url
}

const mergePdfs = async (files: (File | URL)[], convertApi: ConvertApi): Promise<string> => {
  const params = convertApi.createParams()
  files.forEach((file, index) => params.add(`Files[${index}]`, file))
  const mergedResult = await convertApi.convert('pdf', 'merge', params)
  return mergedResult.files[0].Url
}

const trackFileConverted = () => trackEvent('convert-api-merge-files-done')

export const convertAndMergeFiles = async (files: File[], convertApi: ConvertApi, fileName: string): Promise<File> => {
  const resultFileName = `${fileName}.pdf`
  const nonPdfFiles = files.filter((file) => file.type !== 'application/pdf')
  const pdfFiles = files.filter((file) => file.type === 'application/pdf')

  if (nonPdfFiles.length === files.length) {
    const mergedFileUrl = await imagesToPdf(nonPdfFiles, convertApi)
    trackFileConverted()
    return downloadFile(mergedFileUrl, resultFileName)
  }
  if (pdfFiles.length === files.length) {
    const mergePdfsUrl = await mergePdfs(pdfFiles, convertApi)
    trackFileConverted()
    return downloadFile(mergePdfsUrl, resultFileName)
  }

  const mergedFileUrl = await imagesToPdf(nonPdfFiles, convertApi)
  const filesToAdd = [...pdfFiles, new URL(mergedFileUrl)]

  const mergePdfsUrl = await mergePdfs(filesToAdd, convertApi)
  trackFileConverted()
  return downloadFile(mergePdfsUrl, resultFileName)
}

type ConvertApiTokenResponse = {
  token: string
}

export const mergeFilesToPdf = async (files: File[], fileName: string): Promise<File> => {
  if (isTestEnv()) {
    return Promise.resolve(files[0])
  }
  const convertApi = await initializeConvertApi()

  return convertAndMergeFiles(files, convertApi, fileName)
}

export const convertFileToPdf = async (file: File, fileName: string): Promise<File> => {
  if (isTestEnv()) {
    return Promise.resolve(file)
  }

  const convertApi = await initializeConvertApi()

  const fileUrl = await imagesToPdf([file], convertApi)

  return downloadFile(fileUrl, `${fileName}.pdf`)
}

import socialSecurityCard from './social_security_card.png'
import drivingLicense from './driving_license.png'
import resume from './resume.png'
import passport from './passport.png'
import recepisse from './recepisse.png'
import identityProof from './identity_proof.png'
import residencePermit from './residence_permit.png'
import socialSecurityCertificate from './social_security_certificate.png'

const DOCUMENT_GROUP_ICON_BY_SLUG: Record<string, string> = {
  social_security_card: socialSecurityCard as string,
  driving_license: drivingLicense as string,
  resume: resume as string,
  passport: passport as string,
  recepisse: recepisse as string,
  identity_proof: identityProof as string,
  identity_card: identityProof as string,
  residence_permit: residencePermit as string,
  residence_permit_card: residencePermit as string,
  social_security_certificate: socialSecurityCertificate as string,
}

export default DOCUMENT_GROUP_ICON_BY_SLUG

import classNames from 'classnames'
import React from 'react'
import { TDocument } from 'types/documents'
import { isMobile } from 'utils/environment'
import { MobilePdfViewer } from './DocumentViewer/MobilePdfViewer'

type DocumentViewerProps = {
  document: TDocument
  className?: string
  mobileDisplayDisabled?: boolean
}

const DocumentViewer = ({ document, className, mobileDisplayDisabled = false }: DocumentViewerProps) => {
  switch (document.content_type) {
    case 'application/pdf': // TODO: doesn't work to preview PDF before upload in Chrome (like in DocumentUpload.tsx)
      if (!isMobile() || mobileDisplayDisabled) {
        return (
          <object type="application/pdf" data={document.url} className={className} height="100%" width="100%">
            <a href={document.url} target="_blank" rel="noopener noreferrer">
              Impossible de visualiser le PDF. Cliquez ici pour le voir
            </a>
          </object>
        )
      }
      return <MobilePdfViewer document={document} />
    case 'image/png':
    case 'image/jpeg':
      return <img src={document.url} alt={document.label} className={classNames(className, 'object-contain')} />
    default:
      return null
  }
}

export default DocumentViewer

import { initAmplitude } from './amplitude'

type CookieType = 'necessary' | 'functional' | 'analytics' | 'performance' | 'advertisement' | 'other'

type ConsentUpdateDetail = {
  accepted: CookieType[]
  rejected: CookieType[]
}

type ConsentUpdate = {
  detail: ConsentUpdateDetail
}

const onAnalyticsAccepted = () => {
  initAmplitude()
}

export const setupCookiesCallback = () => {
  document.addEventListener('cookieyes_consent_update', (eventData) => {
    const consentEvent = eventData as unknown as ConsentUpdate

    if (consentEvent.detail.accepted.includes('analytics')) {
      onAnalyticsAccepted()
    }
  })
}

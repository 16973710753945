import React, { useState } from 'react'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import Text from 'components/Text'
import IconButton from 'components/IconButton'
import Icon from 'components/Icon'
import PasswordCreationInput from 'components/PasswordCreationInput'
import Button from 'components/Button'

type ResetPasswordProps = {
  className?: string
  resetPassword: (password: string) => void
}

const ResetPassword = ({ resetPassword, className }: ResetPasswordProps) => {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    resetPassword(password)
  }

  return (
    <div className={className}>
      <IconButton
        onClick={() => navigate('/')}
        label={i18next.t('common.back')}
        name="arrow_back"
        className="mb-6 self-start"
        iconClassName="w-6 h-6 mt-6 mb-4"
      />
      <div className="px-6 py-12">
        <div className="flex grow flex-col">
          <div className="w-14 h-14 bg-white rounded-full flex items-center justify-center mb-6 self-center">
            <Icon name="lock" size="large" />
          </div>
          <Text type="title" className="text-center">
            {i18next.t('common.reset_password.title')}
          </Text>
          <div className="mt-6">
            <Text type="text">{i18next.t('common.reset_password.description')}</Text>
            <form onSubmit={onSubmit} className="mt-6">
              <PasswordCreationInput
                className={classNames('w-full min-h-32')}
                label={i18next.t('common.reset_password.password_label')}
                value={password}
                onChange={(newValue) => setPassword(newValue)}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                setLoading={setLoading}
              />
              <Button
                type="submit"
                name={i18next.t('common.reset_password.submit')}
                className="w-full mt-6"
                disabled={loading || !!errorMessage}
              >
                {i18next.t('common.reset_password.submit')}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword

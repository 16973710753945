import usePdfViewer from 'hooks/usePdfViewer'
import React, { useEffect } from 'react'
import Modal from 'react-modal'
import i18next from 'i18next'
import { TDocument } from 'types/documents'
import IconButton from 'components/IconButton'
import Text from 'components/Text'

type FullScreenPdfModalProps = {
  closeModal: () => void
  document: TDocument
}

const FullScreenPdfModal = ({ document, closeModal }: FullScreenPdfModalProps) => {
  const { openPdf, nextPage, previousPage, numPages, currentPage } = usePdfViewer(document.url, 'pdf_canvas_modal')

  useEffect(() => {
    openPdf()
  }, [])

  return (
    <Modal isOpen onRequestClose={closeModal}>
      <IconButton
        name="close"
        className="absolute right-1 top-1"
        size="large"
        uiStyle="accent"
        onClick={closeModal}
        label={i18next.t('common.close')}
      />
      <canvas id="pdf_canvas_modal" className="w-full mt-4" />
      <div className="absolute bottom-2 right-2 left-2 ">
        <div className="relative flex grow items-center justify-center mx-2 mb-2">
          {currentPage > 1 && (
            <IconButton
              name="chevron_left"
              className="absolute left-0 bg-backgroundPrimary"
              size="large"
              uiStyle="accent"
              rounded
              onClick={previousPage}
              label={i18next.t('common.previous')}
            />
          )}
          <Text type="subtext" className="text-center">
            {currentPage} / {numPages}
          </Text>

          {currentPage < numPages && (
            <IconButton
              className="absolute right-0 bg-backgroundPrimary"
              name="chevron_right"
              size="large"
              uiStyle="accent"
              rounded
              onClick={nextPage}
              label={i18next.t('common.next')}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default FullScreenPdfModal

import React from 'react'
import i18next from 'i18next'
import Text from 'components/Text'
import Icon from 'components/Icon'
import Divider from 'components/Divider'
import { TAgency } from 'employee/types/agencies'
import ClickableDiv from 'components/ClickableDiv'

type RowActiveProps = {
  agency: TAgency
  onClick: (agency: TAgency) => void
}

const RowActive = ({ agency, onClick }: RowActiveProps) => (
  <>
    <ClickableDiv
      label={agency.name}
      onClick={() => onClick(agency)}
      className="flex py-4 items-center justify-between gap-4"
    >
      <img
        alt={i18next.t('employee.agency_list.agency_logo', { name: agency.name })}
        src={agency.logo}
        className="w-8 h-8 shrink-0 object-contain"
      />
      <div className="grow overflow-hidden">
        <Text type="text">{agency.name}</Text>
        <Text type="subtext" className="text-ellipsis whitespace-nowrap	overflow-hidden">
          {agency.address}
        </Text>
      </div>
      <Icon name="chevron_right" className="shrink-0" />
    </ClickableDiv>
    <Divider />
  </>
)

export default RowActive

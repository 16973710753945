import React from 'react'
import Modal from 'react-modal'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import i18next from 'i18next'
import { locale as daysjsLocale } from 'dayjs'

import SpaUpdater from 'components/SpaUpdater'
import PageWrapper from 'components/PageWrapper'
import { initSentry, wrappedCreateBrowserRouter } from 'utils/sentry'
import { getDefaultQueryClient } from 'utils/react-query'
import { setupCookiesCallback } from 'utils/cookies'
import ErrorPage from 'components/ErrorPage'
import Toaster from './components/Toaster'
import Signup from './pages/Signup'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import AgencyList from './pages/AgencyList'
import DocumentList from './pages/DocumentList'
import Home from './pages/Home'
import Skeleton from './pages/Skeleton'
import PublicRoutes from './pages/PublicRoutes'
import Account from './pages/Account'
import PrivacySettings from './pages/PrivacySettings'
import DocumentGroupList from './pages/DocumentGroupList'
import DocumentShow from './pages/DocumentShow'
import Agency from './pages/Agency'
import DocumentGroupFolder from './pages/DocumentGroupFolder'

Modal.setAppElement('#root')

const queryClient = getDefaultQueryClient()

initSentry('employee')
setupCookiesCallback()

const App = () => {
  const router = wrappedCreateBrowserRouter(
    createRoutesFromElements(
      <Route element={<SpaUpdater />} errorElement={<ErrorPage />}>
        <Route path="/" element={<Home />} />

        <Route element={<Skeleton />}>
          <Route
            path="/document_groups"
            element={<PageWrapper title={i18next.t('employee.document_groups.title')} component={DocumentList} />}
          />
          <Route
            path="/agencies"
            element={<PageWrapper title={i18next.t('employee.agency_list.accepted_invites')} component={AgencyList} />}
          />
          <Route
            path="/agencies/:agencyId"
            element={
              <PageWrapper paramKeys={['agencyId']} title={i18next.t('employee.agency.title')} component={Agency} />
            }
          />
          <Route
            path="/account"
            element={<PageWrapper title={i18next.t('employee.account.title')} component={Account} />}
          />
          <Route
            path="/account/privacy_settings"
            element={<PageWrapper title={i18next.t('employee.account.privacy.title')} component={PrivacySettings} />}
          />
        </Route>
        <Route
          path="/document_groups/:documentGroupId/:slug/list"
          element={
            <PageWrapper
              paramKeys={['documentGroupId', 'slug']}
              title={i18next.t('employee.document_group.title')}
              component={DocumentGroupList}
            />
          }
        />
        <Route
          path="/document_groups/:documentGroupId/:slug/folder"
          element={
            <PageWrapper
              paramKeys={['documentGroupId', 'slug']}
              title={i18next.t('employee.document_group.title')}
              component={DocumentGroupFolder}
            />
          }
        />
        <Route
          path="/document_groups/:documentGroupId/:slug/"
          element={
            <PageWrapper
              paramKeys={['documentGroupId', 'slug']}
              title={i18next.t('employee.document_group.title')}
              component={DocumentShow}
            />
          }
        />

        <Route element={<PublicRoutes />}>
          <Route path="/login" element={<PageWrapper title={i18next.t('employee.login.title')} component={Login} />} />
          <Route
            path="/employee/password/new"
            element={<PageWrapper title={i18next.t('employee.forgot_password.title')} component={ForgotPassword} />}
          />
          <Route
            path="/employee/password/edit"
            element={<PageWrapper title={i18next.t('common.reset_password.title')} component={ResetPassword} />}
          />
          <Route
            path="/signup/emails/new"
            element={<PageWrapper title={i18next.t('employee.signup.title')} component={Signup} />}
          />
        </Route>
      </Route>
    )
  )

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root')
  container?.classList.add('max-w-3xl', 'mx-auto')
  if (container) {
    i18next.init({
      lng: 'fr',
      resources: window.translation_keys,
    })
    daysjsLocale('fr')

    const root = createRoot(container)
    root.render(<App />)
  }
})

import { useEffect } from 'react'

const useDocumentTitle = (title?: string) => {
  useEffect(() => {
    if (title) {
      document.title = `Skribe | ${title}`
    } else {
      document.title = 'Skribe'
    }
  }, [title])
}

export default useDocumentTitle

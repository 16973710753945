import React, { useState } from 'react'
import i18next from 'i18next'
import Input from './Input'
import IconButton from './IconButton'

type PasswordInputProps = {
  value: string
  label?: string
  maxLength?: number
  onChange: (value: string) => void
  error: string | undefined
  className?: string
}

const PasswordInput = ({
  value,
  maxLength,
  onChange,
  error,
  className,
  label = i18next.t('common.password'),
}: PasswordInputProps) => {
  const [displayPassword, setDisplayPassword] = useState(false)

  return (
    <Input
      name="password"
      label={label}
      type={displayPassword ? 'text' : 'password'}
      placeholder="********"
      value={value}
      maxLength={maxLength}
      className={className}
      onChange={onChange}
      error={error}
      required
      rightIcon={
        <IconButton
          label={i18next.t('display_password')}
          name={displayPassword ? 'visibility_off' : 'visibility'}
          uiStyle="accent"
          onClick={() => setDisplayPassword(!displayPassword)}
        />
      }
    />
  )
}

export default PasswordInput

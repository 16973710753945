import React, { PropsWithChildren, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const PublicRoutes = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (window.logged_in) {
      navigate('/')
    }
  }, [])
  return children ?? <Outlet />
}

export default PublicRoutes

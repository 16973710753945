import i18next from 'i18next'
import React, { InputHTMLAttributes, forwardRef } from 'react'
import toast from 'react-hot-toast'

type CommonInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>

type UploadInputProps = CommonInputProps & {
  onChange: (file: File) => void
}

const ACCEPTED_TYPES = ['image/', 'application/pdf']

const isFileExtensionAccepted = (file: File) => ACCEPTED_TYPES.some((type) => file.type.includes(type))

export const validateFiles = (files: File[]) => {
  const hasInvalidFiles = files.some((file) => !isFileExtensionAccepted(file))

  if (hasInvalidFiles) {
    toast.error(i18next.t('common.document_upload.invalid_type'))
    return false
  }
  return true
}

export const UploadInput = forwardRef(
  ({ capture, onChange, accept = 'image/*,.pdf' }: UploadInputProps, ref: React.ForwardedRef<HTMLInputElement>) => (
    <input
      ref={ref}
      type="file"
      accept={accept}
      capture={capture}
      onChange={(event) => {
        const file = event.target.files?.[0]
        if (!file) return
        if (!validateFiles([file])) return
        onChange(file)
      }}
    />
  )
)

type MultipleFilesInputProps = CommonInputProps & {
  onChange: (files: File[]) => void
  numberOfFiles: number
}

export const MultipleUploadInput = forwardRef(
  (
    { onChange, accept = 'image/*,.pdf', numberOfFiles }: MultipleFilesInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => (
    <input
      ref={ref}
      type="file"
      accept={accept}
      multiple={numberOfFiles > 1}
      onChange={(event) => {
        const fileList = event.target.files ?? []
        if (fileList.length === 0) return

        if (fileList.length > numberOfFiles) {
          toast.error(i18next.t('common.document_upload.too_many_files', { count: numberOfFiles }))
          return
        }
        const files: File[] = [...fileList] // Transform FileList to File[]
        if (!validateFiles(files)) return
        onChange(files)
      }}
    />
  )
)

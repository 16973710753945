import React from 'react'

const SkribeLogoShort = () => (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.4704 25.2737C38.162 29.1115 31.6011 31.3797 24.5 31.3797H0.5C0.5 30.2199 0.564167 29.0748 0.68921 27.9476C2.43261 12.2317 16.0096 0 32.5 0H56.5C56.5 1.15983 56.4358 2.30495 56.3108 3.43216C55.4254 11.4136 51.4878 18.4965 45.6666 23.5348C45.4 23.7656 45.1294 23.992 44.855 24.2141C44.4037 24.5794 43.942 24.9327 43.4704 25.2737Z"
      fill="#00856D"
    />
    <path
      d="M7.87787 35.9559C3.27055 41.3923 0.5 48.3789 0.5 56H24.5C42.1731 56 56.5 41.9508 56.5 24.6203H51.2224C50.4375 25.4393 49.6139 26.2221 48.7547 26.9658C48.4494 27.23 48.1396 27.4893 47.8254 27.7436C47.3086 28.1618 46.7798 28.5665 46.2397 28.957C40.1564 33.3552 32.6311 35.9559 24.5 35.9559H7.87787Z"
      fill="#00856D"
    />
  </svg>
)

export default SkribeLogoShort

/* eslint-disable import/extensions */
import { getDocument, GlobalWorkerOptions, PDFDocumentProxy } from 'pdfjs-dist'
// @ts-expect-error Unusual import
import * as pdfWorker from 'pdfjs-dist/build/pdf.worker.js'

// Setting worker path to worker bundle.
// eslint-disable-next-line
GlobalWorkerOptions.workerSrc = pdfWorker

export { getDocument, PDFDocumentProxy }

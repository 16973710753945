import constants from 'utils/constants'
import { convertFileToPdf, mergeFilesToPdf } from './convert-api'
import { genericHttpClient } from './http-client'
import { compressImageSize } from './files'
import { trackEvent } from './amplitude'

export const UPLOAD_FILE_SIZE_ERROR = 'file_too_big'
export const UPLOAD_FILE_TYPE_ERROR = 'invalid_file_type'

const shouldReduceFileSize = (file: File) => file.size > constants.MAX_FILE_SIZE && file.type.includes('image/')

const compressAllPossibleFiles = async (files: File[]) => {
  const filesAndReducedImages: Promise<File>[] = files.map((file) => {
    if (file.type.includes('image/')) {
      return compressImageSize(file)
    }
    return new Promise((resolve) => {
      resolve(file)
    })
  })
  return Promise.all(filesAndReducedImages)
}

export const prepareFiles = async (files: File[], outputFileName: string): Promise<File> => {
  let fileToUpload = files[0]
  if (files.length > 1) {
    const totalSize = files.reduce((accumulator, file) => accumulator + file.size, 0)

    trackEvent('convert-api-merge-files')
    if (totalSize > constants.MAX_FILE_SIZE) {
      const compressedFiles = await compressAllPossibleFiles(files)
      fileToUpload = await mergeFilesToPdf(compressedFiles, outputFileName)
    } else {
      fileToUpload = await mergeFilesToPdf(files, outputFileName)
    }
    trackEvent('convert-api-merge-files-downloaded')
  }

  if (shouldReduceFileSize(fileToUpload)) {
    fileToUpload = await compressImageSize(fileToUpload)
  }

  if (fileToUpload.size > constants.MAX_FILE_SIZE) {
    throw new Error(UPLOAD_FILE_SIZE_ERROR)
  }

  return fileToUpload
}

export const convertFileToPdfIfNeeded = async (file: File, outputFileName: string): Promise<File> => {
  if (!['image/heic', 'image/tiff'].includes(file.type)) {
    return Promise.resolve(file)
  }
  const convertedFile = await convertFileToPdf(file, outputFileName)

  if (convertedFile.size > constants.MAX_FILE_SIZE) {
    throw new Error(UPLOAD_FILE_SIZE_ERROR)
  }

  return convertedFile
}

const postFile = async <T>(url: string, file: File, data: Record<string, unknown>) => {
  const formData = new FormData()

  formData.append('file', file)

  formData.append('data', JSON.stringify(data))

  return genericHttpClient<T>(url, 'post', {}, formData)
}

export default postFile

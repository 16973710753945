import React, { useState } from 'react'
import toast from 'react-hot-toast'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Button'
import CheckBox from 'components/CheckBox'
import { useEmployee, useUpdateSettings } from 'employee/queries/employees'
import AccountLayout from './Account/AccountLayout'

const PrivacySettings = () => {
  const { data: employee } = useEmployee()
  const [dataUsageConsent, setDataUsageConsent] = useState(!!employee.dataUsageConsentedAt)
  const navigate = useNavigate()

  const { mutate: updateSettings } = useUpdateSettings({
    onSuccess: () => {
      toast.success(i18next.t('employee.account.privacy.success'))
    },
  })

  const onChange = (value: boolean) => {
    setDataUsageConsent(value)
    updateSettings({ dataUsageConsent: value })
  }

  return (
    <AccountLayout title={i18next.t('employee.account.privacy.title')} onBackPress={() => navigate(-1)}>
      <CheckBox
        onChange={onChange}
        name="skribe_document_terms"
        label={i18next.t('employee.signup.disclaimer.document_usage_terms')}
        checked={dataUsageConsent}
        containerClassName="mb-6"
      />
      <Button name="reset_cookies" className="cky-banner-element" type="button" onClick={() => {}}>
        {i18next.t('employee.account.privacy.cookies')}
      </Button>
    </AccountLayout>
  )
}

export default PrivacySettings

import React from 'react'
import i18next from 'i18next'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Input from 'components/Input'
import Button from 'components/Button'
import Icon from 'components/Icon'
import Text from 'components/Text'
import IconButton from 'components/IconButton'

type ForgotPasswordFormProps = {
  description: React.ReactElement
  defaultValue: string
  resetPassword: (email: string) => Promise<void>
  onNext: () => void
}

const ForgotPasswordForm = ({ description, resetPassword, onNext, defaultValue }: ForgotPasswordFormProps) => {
  const [email, setEmail] = React.useState(defaultValue)
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    resetPassword(email).then(onNext)
  }
  // TODO: on error, display a toast
  return (
    <div className="mt-6">
      {description}
      <form onSubmit={onSubmit} className="mt-6">
        <Input
          name="email"
          label={i18next.t('common.email')}
          type="email"
          placeholder={i18next.t('common.email_placeholder')}
          value={email}
          onChange={(newValue) => {
            setEmail(newValue)
          }}
          required
        />
        <Button
          type="submit"
          name={i18next.t('common.forgot_password.submit')}
          className="w-full mt-6"
          disabled={!email}
        >
          {i18next.t('common.forgot_password.submit')}
        </Button>
      </form>
    </div>
  )
}

type ForgotPasswordProps = {
  className?: string
  title: string
  description: React.ReactElement
  resetPassword: (email: string) => Promise<void>
}

const ForgotPassword = ({ className, title, description, resetPassword }: ForgotPasswordProps) => {
  const [submitted, setSubmitted] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (searchParams.get('expired')) {
      toast.error(i18next.t('common.forgot_password.link_expired'))
      searchParams.delete('expired')
      setSearchParams(searchParams)
    }
  }, [searchParams])

  return (
    <div className={className}>
      <IconButton
        onClick={() => navigate('/')}
        label={i18next.t('common.back')}
        name="arrow_back"
        className="mb-6 self-start"
        iconClassName="w-6 h-6 mt-6 mb-4"
      />
      <div className="px-6 py-12">
        <div className="flex flex-col place-items-center">
          <div className="w-14 h-14 bg-white rounded-full flex items-center justify-center mb-6 self-center">
            <Icon name="lock" size="large" />
          </div>
          <Text type="title" className="text-center">
            {title}
          </Text>
          {submitted ? (
            <div>
              <Text type="subtext" className="mt-2 text-center">
                {i18next.t('common.forgot_password.sent')}
              </Text>
              <Text type="text" className="mt-6 text-center">
                {i18next.t('common.forgot_password.check_email')}
              </Text>
            </div>
          ) : (
            <ForgotPasswordForm
              description={description}
              resetPassword={resetPassword}
              onNext={() => setSubmitted(true)}
              defaultValue={searchParams.get('email') ?? ''}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword

export const csrfToken = () => document.querySelector<HTMLMetaElement>('[name=csrf-token]')?.content ?? ''

const USER_NOT_LOGGED_IN = 'User not logged in'

export const updateAppVersion = (headers: Headers) => {
  const sha1 = headers.get('X-App-Version')
  if (sha1 && window.setAppVersionSha1) {
    window.setAppVersionSha1(sha1)
  }
}

export class HttpClientError extends Error {
  response?: Response

  constructor({ error, response }: { error?: TypeError; response?: Response }) {
    super(error?.message ?? response?.statusText)
    this.response = response
  }
}

type HttpMethodArgs = 'get' | 'post' | 'put' | 'delete'

type HttpClientArgs = {
  method: HttpMethodArgs
  body?: Record<string, unknown> | BodyInit
}

export const genericHttpClient = <T>(
  url: string,
  method: HttpMethodArgs,
  headers: HeadersInit,
  body: BodyInit
): Promise<T> =>
  fetch(url, {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'X-CSRF-TOKEN': csrfToken(),
      ...(headers || {}),
    },
    body,
  })
    .catch((error) => {
      if (error instanceof TypeError) {
        throw new HttpClientError({ error })
      } else throw error
    })
    .then(async (response) => {
      updateAppVersion(response.headers)
      if (response.ok) {
        const contentType = response.headers.get('content-type')
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json() as Promise<T>
        }
        return response as T
      }
      const error = (await response.json()) as Error | undefined

      if (response.status === 401) {
        if (error?.message === USER_NOT_LOGGED_IN) {
          window.logged_in = false
          window.location.href = '/'
        }
      }
      throw new HttpClientError({ response, error })
    })

const httpClient = <T>(
  url: string,
  { method = 'get', body }: HttpClientArgs = { method: 'get' },
  headers: HeadersInit = { 'Content-Type': 'application/json' }
) => genericHttpClient<T>(url, method, headers, JSON.stringify(body))

export default httpClient

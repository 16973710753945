import Icon, { IconProps } from 'components/Icon'
import IconButton from 'components/IconButton'
import i18next from 'i18next'
import React from 'react'

type DocumentGroupPageContainerProps = {
  children: React.ReactNode
  iconName: IconProps['name']
  onBackPressed: () => void
  id: string
}

const DocumentGroupPageContainer = ({ id, children, onBackPressed, iconName }: DocumentGroupPageContainerProps) => (
  <div id={id} className="py-4 px-6 w-full min-h-dvh flex flex-col grow bg-backgroundPrimary">
    <IconButton
      name="arrow_back"
      label={i18next.t('common.back')}
      onClick={onBackPressed}
      className="w-6 h-6 mb-4 mt-6"
    />
    <div className="w-14 h-14 bg-white rounded-full flex items-center justify-center mb-6 self-center shrink-0">
      <Icon name={iconName} size="large" uiStyle="success" />
    </div>

    {children}
  </div>
)

export default DocumentGroupPageContainer

import React from 'react'
import i18next from 'i18next'
import IdentityPhotoAdvice from 'images/identity_photo_advice.png'
import AdviceModal from './components/AdviceModal'

type IdentityPhotoAdviceModalProps = {
  onClose: () => void
  onContinueClick: () => void
}

const IdentityPhotoAdviceModal = ({ onClose, onContinueClick }: IdentityPhotoAdviceModalProps) => (
  <AdviceModal
    onClose={onClose}
    onButtonClick={onContinueClick}
    title={i18next.t('employee.document_groups.upload_method_drawer.upload_advice.title')}
    info={i18next.t('employee.document_groups.upload_method_drawer.upload_advice.identity_photo.info')}
    buttonText={i18next.t('common.continue')}
  >
    <img
      className="max-h-80 mx-auto"
      src={IdentityPhotoAdvice as string}
      alt={i18next.t('employee.document_groups.upload_method_drawer.upload_advice.title')}
    />
  </AdviceModal>
)

export default IdentityPhotoAdviceModal

import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDocumentDestroy, useDocumentGroup } from 'employee/queries/document_groups'
import toast from 'react-hot-toast'
import i18next from 'i18next'

import Document from 'employee/components/Document'
import ConfirmModal from 'employee/components/ConfirmModal'
import DocumentUploadFlow from 'employee/components/DocumentUploadFlow'
import { EmployeeUploadedDocument } from 'employee/types/documents'

type DocumentViewerProps = {
  documentGroupId: string
  slug: string
}

const getDocument = (
  documents?: EmployeeUploadedDocument[],
  documentId?: string | null
): EmployeeUploadedDocument | undefined => {
  if (!documents) {
    return undefined
  }
  if (!documentId) {
    return documents[0]
  }
  return documents.find((document) => document.id === documentId)
}

const DocumentShow = ({ documentGroupId, slug }: DocumentViewerProps) => {
  const [searchParams] = useSearchParams()

  const documentId = searchParams.get('documentId')
  const [displayDrawer, setDisplayDrawer] = useState<boolean>(false)
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false)

  const navigate = useNavigate()

  const { data: documentGroup } = useDocumentGroup(documentGroupId)
  const { mutate: deleteDocument } = useDocumentDestroy({
    onSuccess: () => {
      toast.success(i18next.t('employee.document_groups.document_deleted'))
      navigate(-1)
    },
    onError: () => toast.error(i18next.t('common.unexpected_error')),
  })

  const document = getDocument(documentGroup.documents[slug], documentId)

  const onDeleteClick = () => {
    if (!document) return
    deleteDocument({ documentGroupId, documentId: document.id })
  }

  return (
    <>
      <Document
        slug={slug}
        documents={document ? [document] : []}
        documentGroup={documentGroup}
        buttonVisible={documentGroup.kind !== 'folder'}
        buttonLabel={i18next.t('employee.document_groups.document_group_upload.replace_file')}
        onClick={() => setDisplayDrawer(true)}
        onDeleteClick={() => {
          setShowDeleteConfirmModal(true)
        }}
      />

      <ConfirmModal
        open={showDeleteConfirmModal}
        onCancelClick={() => setShowDeleteConfirmModal(false)}
        onConfirmClick={onDeleteClick}
        title={i18next.t('employee.document_groups.document_deleted_question')}
      />
      {displayDrawer ? (
        <DocumentUploadFlow slug={slug} documentGroupId={documentGroupId} onClose={() => setDisplayDrawer(false)} />
      ) : null}
    </>
  )
}

export default DocumentShow

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (window.logged_in) {
      navigate(window.redirect_path)
    } else {
      navigate('/login')
    }
  }, [])

  return null
}

export default Home

import React from 'react'
import classNames from 'classnames'
import Modal from 'react-modal'
import i18next from 'i18next'
import Divider from 'components/Divider'
import ClickableDiv from 'components/ClickableDiv'
import Text from 'components/Text'
import Icon from 'components/Icon'

type BackImageSelectionProps = {
  onFileSelect: () => void
}

const BackImageSelection = ({ onFileSelect }: BackImageSelectionProps) => (
  <Modal
    isOpen
    overlayClassName="fixed inset-0 bg-modalBackrop flex justify-center items-end"
    className={classNames('px-6 pt-8 pb-12 bg-white rounded-t-md w-full max-w-3xl')}
  >
    <Text type="title" className="text-xl">
      {i18next.t('employee.document_groups.back_image_drawer.title')}
    </Text>
    <Text type="subtext" className="mt-1">
      {i18next.t('employee.document_groups.back_image_drawer.subtitle')}
    </Text>
    <Divider className="my-6" />

    <ClickableDiv label="existing" onClick={onFileSelect} className="flex items-center gap-3">
      <Icon name="filter_center_focus" className="w-8 h-8" />
      <Text type="text">{i18next.t('employee.document_groups.upload_method_drawer.take_picture')}</Text>
    </ClickableDiv>
  </Modal>
)

export default BackImageSelection

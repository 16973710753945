import React, { useRef, useEffect, useLayoutEffect } from 'react'
import { useLocation, Outlet } from 'react-router-dom'

export const useSpaUpdater = () => {
  const needsUpdate = useRef(false)
  const location = useLocation()

  useEffect(() => {
    window.setAppVersionSha1 = (newValue: string) => {
      if (newValue !== window.application_version_sha1) {
        needsUpdate.current = true
      }
    }
    return () => {
      window.setAppVersionSha1 = undefined
    }
  }, [])

  useLayoutEffect(() => {
    if (needsUpdate.current) {
      window.location.reload()
    }
  }, [location.pathname])
}

const SpaUpdater = () => {
  useSpaUpdater()

  return <Outlet />
}

export default SpaUpdater

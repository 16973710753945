import React from 'react'
import i18next from 'i18next'
import Divider from 'components/Divider'
import Text from 'components/Text'

const DocumentContent = ({ expirationDate, requestedBy }: { expirationDate?: string; requestedBy: string[] }) => (
  <div>
    {expirationDate && (
      <div className="flex items-center justify-between">
        <Text type="subtext">{i18next.t('employee.document_groups.document_group_upload.expiry_date')}</Text>
        <input type="date" className="w-1/2" defaultValue={expirationDate} />
      </div>
    )}
    {requestedBy && (
      <>
        <Divider className="my-3" />
        <div className="flex items-start justify-between">
          <Text type="subtext">{i18next.t('employee.document_groups.document_group_upload.requested_by')}</Text>
          <Text type="text" className="w-1/2 text-ellipsis whitespace-wrap overflow-y-hidden">
            {requestedBy}
          </Text>
        </div>
      </>
    )}
    <Divider className="my-3" />
  </div>
)

export default DocumentContent

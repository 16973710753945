import Divider from 'components/Divider'
import IconButton from 'components/IconButton'
import Text from 'components/Text'
import i18next from 'i18next'
import React, { PropsWithChildren } from 'react'

type AccountLayoutProps = {
  title: string
  onBackPress: () => void
}

const AccountLayout = ({ children, title, onBackPress }: PropsWithChildren<AccountLayoutProps>) => (
  <div>
    <div className="flex flex-row px-3 py-5 text-center">
      <IconButton name="arrow_back" label={i18next.t('common.back')} onClick={onBackPress} />
      <Text type="text" uiStyle="bold" className="grow">
        {title}
      </Text>
      <div />
    </div>
    <Divider className="my-1" />
    <div className="px-4 pt-5">{children}</div>
  </div>
)

export default AccountLayout

import useDocumentTitle from 'hooks/useDocumentTitle'
import React from 'react'
import { useParams } from 'react-router-dom'

type PageWrapperProps = {
  paramKeys?: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (params: any) => React.JSX.Element | null
  title: string
}

const PageWrapper = ({ paramKeys, component: Component, title, ...props }: PageWrapperProps) => {
  const paramsFromUrl = useParams()

  const params: Record<string, string> = {}

  useDocumentTitle(title)

  if (paramKeys) {
    paramKeys.forEach((key: string) => {
      params[key] = paramsFromUrl[key] ?? ''
    })
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...params} {...props} />
}

export default PageWrapper

import React from 'react'
import classNames from 'classnames'

export type TagUiStyle = 'success' | 'info' | 'warning' | 'error' | 'neutral'

type TagProps = {
  className?: string
  uiStyle: TagUiStyle
  onClick?: () => void
  name?: string
  withDot?: boolean
}

const classNameByStyle = {
  primary: 'bg-primary text-white',
  success: 'bg-successLight text-success',
  info: 'bg-infoLight text-info',
  warning: 'bg-warningLight text-warning',
  error: 'bg-errorLight text-error',
  neutral: 'bg-neutralLight text-neutral',
}

const dotClassNameByStyle = {
  success: 'text-successVibrant',
  info: 'text-infoVibrant',
  warning: 'text-warningVibrant',
  error: 'text-errorVibrant',
  neutral: 'text-neutralVibrant',
}

const Dot = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="6"
    height="6"
    viewBox="0 0 6 6"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="3" cy="3" r="3" />
  </svg>
)

const Tag = ({ className, uiStyle, children, onClick, name, withDot }: React.PropsWithChildren<TagProps>) => {
  const fullClassNames = classNames(
    'rounded-[6rem] inline-flex justify-center items-center px-2 py-1',
    className,
    classNameByStyle[uiStyle],
    { 'gap-2': withDot }
  )
  return onClick ? (
    <button className={fullClassNames} type="button" name={name} onClick={onClick}>
      {withDot ? <Dot className={dotClassNameByStyle[uiStyle]} /> : null}
      {children}
    </button>
  ) : (
    <div className={fullClassNames}>
      {withDot ? <Dot className={dotClassNameByStyle[uiStyle]} /> : null}
      {children}
    </div>
  )
}

export default Tag

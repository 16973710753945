import constants from './constants'

export const isTestEnv = (): boolean => Boolean(constants.IS_TEST_ENV)

export const isProductionDeployment = (): boolean => Boolean(constants.IS_PRODUCTION_DEPLOYMENT)

export const isStagingDeployment = (): boolean => Boolean(constants.IS_STAGING_DEPLOYMENT)

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const isMobile = (): boolean => {
  if (navigator.maxTouchPoints > 0) {
    return true
  }
  const mQ = matchMedia?.('(pointer:coarse)')
  if (mQ?.media === '(pointer:coarse)') {
    return !!mQ.matches
  }
  // deprecated, but good fallback
  if ('orientation' in window) {
    return true
  }

  const UA = navigator.userAgent
  return /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
}

import React, { useState } from 'react'
import classNames from 'classnames'
import Modal from 'react-modal'
import i18next from 'i18next'
import { isMobile } from 'utils/environment'
import { trackEvent } from 'utils/amplitude'
import Divider from 'components/Divider'
import ClickableDiv from 'components/ClickableDiv'
import Text from 'components/Text'
import Icon from 'components/Icon'
import { photoAdvices } from './advices'

export type UploadMethod = 'camera' | 'file'

type FirstFileSelectionProps = {
  onClose: () => void
  slug: string
  onFileSelect: (method: UploadMethod) => void
}

const FirstFileSelection = ({ onClose, onFileSelect, slug }: FirstFileSelectionProps) => {
  const [showAdvice, setShowAdvice] = useState(false)

  const AdviceComponent = photoAdvices[slug]

  const onContinueClick = () => {
    onFileSelect('camera')
    setShowAdvice(false)
  }

  const trackFileUpload = (type: 'camera' | 'file') => {
    trackEvent('file-upload', { uploadType: type, documentSlug: slug })
  }

  const onTakePictureClick = () => {
    trackFileUpload('camera')
    if (AdviceComponent) {
      setShowAdvice(true)
    } else {
      onContinueClick()
    }
  }

  const onExistingFileClick = () => {
    trackFileUpload('file')
    onFileSelect('file')
  }

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      overlayClassName="fixed inset-0 bg-modalBackrop flex justify-center items-end"
      className={classNames('px-6 pt-8 pb-12 bg-white rounded-t-md w-full max-w-3xl')}
    >
      <Text type="title" className="text-xl">
        {i18next.t('employee.document_groups.upload_method_drawer.title')}
      </Text>
      <Text type="subtext" className="mt-1">
        {i18next.t('common.document_upload.accepted_formats')}
      </Text>
      <Text type="subtext">{i18next.t('common.document_upload.max_size')}</Text>
      <Divider className="my-6" />

      {isMobile() && (
        <ClickableDiv label="existing" onClick={onTakePictureClick} className="flex items-center gap-3 mb-6">
          <Icon name="filter_center_focus" className="w-8 h-8" />
          <Text type="text">{i18next.t('employee.document_groups.upload_method_drawer.take_picture')}</Text>
        </ClickableDiv>
      )}

      <ClickableDiv label="existing" onClick={onExistingFileClick} className="flex items-center gap-3">
        <Icon name="file_present" className="w-8 h-8" />
        <Text type="text">{i18next.t('employee.document_groups.upload_method_drawer.choose_existing_file')}</Text>
      </ClickableDiv>
      {showAdvice && <AdviceComponent onClose={() => setShowAdvice(false)} onContinueClick={onContinueClick} />}
    </Modal>
  )
}

export default FirstFileSelection

import toast from 'react-hot-toast'
import { captureException } from '@sentry/react'
import { QueryCache, QueryClient } from '@tanstack/react-query'

export const getDefaultQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      mutations: {
        onError: (error) => {
          captureException(error)
          toast.error(
            `Une erreur est survenue. Veuillez réessayer plus tard ou contacter le support. Erreur : ${error.message}`
          )
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        captureException(error)
        toast.error(
          `Une erreur est survenue. Veuillez réessayer plus tard ou contacter le support. Erreur : ${error.message}`
        )
      },
    }),
  })

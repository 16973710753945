import React from 'react'
import i18next from 'i18next'
import toast from 'react-hot-toast'
import { useEmployee } from 'employee/queries/employees'
import dayjs from 'dayjs'
import ClickableDiv from 'components/ClickableDiv'
import AdviceModal from './components/AdviceModal'

type DeclarationOfHonorAdviceModalProps = {
  onClose: () => void
  onContinueClick: () => void
}

const DeclarationOfHonorAdviceModal = ({ onClose, onContinueClick }: DeclarationOfHonorAdviceModalProps) => {
  const { data: employee } = useEmployee()

  const content = i18next.t(
    'employee.document_groups.upload_method_drawer.upload_advice.declaration_of_honor.content_html',
    { first_name: employee.firstName, last_name: employee.lastName, date: dayjs().format('DD/MM/YYYY') }
  )

  const onContentClick = () => {
    // Clipboard API is only available in secure (HTTPS) contexts
    if (window.isSecureContext) {
      const doc = new DOMParser().parseFromString(content, 'text/html')
      window.navigator.clipboard.writeText(doc.body.textContent ?? '')
      toast.success(
        i18next.t('employee.document_groups.upload_method_drawer.upload_advice.declaration_of_honor.copied')
      )
    }
  }

  return (
    <AdviceModal
      onClose={onClose}
      onButtonClick={onContinueClick}
      title={i18next.t('employee.document_groups.upload_method_drawer.upload_advice.title')}
      info={i18next.t('employee.document_groups.upload_method_drawer.upload_advice.declaration_of_honor.info')}
      buttonText={i18next.t('common.continue')}
    >
      <div className="flex-col">
        <ClickableDiv
          label={i18next.t('employee.document_groups.upload_method_drawer.upload_advice.declaration_of_honor.copy')}
          onClick={onContentClick}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
      {/* eslint-enable react/no-danger */}
    </AdviceModal>
  )
}

export default DeclarationOfHonorAdviceModal

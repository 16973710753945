import React from 'react'
import classNames from 'classnames'

type ChipProps = {
  className?: string
  size?: 'medium' | 'large'
}

const CLASS_NAMES_BY_SIZE = {
  medium: 'w-10 h-10',
  large: 'w-14 h-14',
}

const Chip = ({ className, children, size = 'medium' }: React.PropsWithChildren<ChipProps>) => (
  <div className={classNames('rounded-full flex items-center justify-center', CLASS_NAMES_BY_SIZE[size], className)}>
    {children}
  </div>
)

export default Chip
